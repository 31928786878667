import Footer from "../components/Footer";
import "../style/smouha.css";
import Navbar from "../components/Navbar";

import MessengerCustomerChat from "react-messenger-customer-chat";
import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";
import iosStore from "../images/iosStore2.svg";
import appStore from "../images/appStore2.svg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { RxDoubleArrowRight } from "react-icons/rx";
import { Button, Modal } from "react-bootstrap";

import about1 from "../images/about1.png";
import about2 from "../images/about2.svg";
import { MdAccountBox } from "react-icons/md";
import { MdBusinessCenter } from "react-icons/md";
import { MdMail } from "react-icons/md";
import { MdPhoneAndroid } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import "../style/quotation.css";
import { BsCheck } from "react-icons/bs";
import { requestQuotation } from "../api";
import { useFormik } from "formik";
import * as yup from "yup";
import { PuffLoader } from "react-spinners";
import Success from "../components/Success";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

function Smouha() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [checkList, setCheckList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);

  const { lang } = useParams(); // Get the language parameter from the URL

  useEffect(() => {
    if (lang && (lang === "en" || lang === "ar")) {
      i18n.changeLanguage(lang);
      localStorage.setItem("language", JSON.stringify(lang));

      if (lang === "en") {
        document.getElementById("root").style.direction = "ltr";
      } else {
        document.getElementById("root").style.direction = "rtl";
      }

      if (JSON.parse(localStorage.getItem("language")) === "ar") {
        document.title = " بدايات للتكنولوجيا";
      } else {
        document.title = "BDAIAT TECHNOLOGIES";
      }
    }
  }, [lang, i18n]);

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const source = queryParams.get('source');

  // // Now 'source' contains the value indicating the button clicked
  // console.log('Source:', source);

  let emailRegx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("language")) === "ar") {
      document.title = "عرض سعر";
    } else {
      document.title = "QUOTATIONS";
    }
  }, []);

  const Schema = yup.object().shape({
    name: yup.string().required("validation.name"),
    phone: yup
      .string()
      .required("validation.phone")
      .min(10, "validation.phoneInvalid"),
    email: yup
      .string()
      .required("validation.email")
      .matches(emailRegx, "validation.emailInvalid"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  const source = localStorage.getItem("source");

  function onSubmit() {
    setLoading(true);
    const data = {
      name: formik.values.name,
      company_name: "smouha-inquiry",
      phone: formik.values.phone,
      email: formik.values.email,
      message: formik.values.message,
      services: 6,
      type: "service",
      source: source,
    };

    requestQuotation(data, i18n.language)
      .then((response) => {
        setLoading(false);
        setErr(false);
        setSuccess(true);
        const checkboxes = document.getElementsByClassName("chooseService");

        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];

          if (checkbox.checked) {
            checkbox.checked = false;
          }
        }
        setCheckList([]);
        /*var frm = document.getElementsByName('quotationFrm')[0];
  frm.reset(); */
        formik.handleReset();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div className="products-container">
      {success ? <Success type="success" setModal={setSuccess} /> : null}
      <div className="container-fluid besalla">
        <div className="row">
          <div
            className={`col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaTextCol textarea-field ${
              currentLanguage === "ar" ? "arabicStyleBesallaTextCol" : ""
            }`}
          >
            <div
              className={`besallaText ${
                currentLanguage === "ar" ? "arabicStyleBesallaText" : ""
              }`}
            >
              <h1
                className={`${
                  currentLanguage === "ar" ? "arabicStyleBesallaTextH1" : ""
                }`}
              >
                {t("smouhaPage.title2")}
              </h1>
              <p>{t("smouhaPage.title")}</p>
              <p
                className={`besallaTextP2 ${
                  currentLanguage === "ar" ? "arabicStyleBesallaTextP2" : ""
                }`}
              >
                {t("smouhaPage.title3")}
              </p>

              <div className="smouhaStores">
                <div className="store">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.bdaiat.smouha"
                    target="_blank"
                  >
                    <img className="appStoreLogo" src={appStore} />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/smouha/id6739978074"
                    target="_blank"
                  >
                    <img className="AppleStore" src={iosStore} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaImgsCol phone-field">
            <div
              className={`bsImgOne ${
                currentLanguage === "ar" ? "arabicStyleBesallaImgsOne" : ""
              }`}
            ></div>
          </div>
        </div>
      </div>

      {/* smouha form */}
      <div
        className={`container-fluid quotationContainer ${
          currentLanguage === "ar" ? "quotationContainerAr" : ""
        }`}
      >
        <div className="row">
          <div
            className={`col-lg-8 col-md-12 quotationForm ${
              currentLanguage === "ar" ? "quotationFormAr" : ""
            }`}
          >
            <form onSubmit={formik.handleSubmit} name="quotationFrm">
              <p id="quotRequest">{t("smouhaPage.quotation")}</p>
              <p id="quotReach">
                {t("services.reach")}{" "}
                <a href={`mailto:yakout@it.bdaiat.com`}>sales@bdaiat.com</a>
              </p>
              {/* <div className=" container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6 nopadding">
                    <div className="input-wrapper">
                      <MdAccountBox className="input-icon" />
                      <input
                        type="text"
                        placeholder={t("services.name")}
                        value={formik.values.name}
                        id="name"
                        name="name"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.name && formik.touched.name
                            ? "error"
                            : null
                        }
                      />
                      <p className="errMsg">
                        {formik.errors.name && formik.touched.name
                          ? t(`${formik.errors.name}`)
                          : null}
                      </p>
                    </div>
                    <div className="input-wrapper">
                      <MdMail className="input-icon" />
                      <input
                        type="text"
                        placeholder={t("services.email")}
                        value={formik.values.email}
                        id="email"
                        name="email"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.email && formik.touched.email
                            ? "error"
                            : null
                        }
                      />
                      <p className="errMsg">
                        {formik.errors.email && formik.touched.email
                          ? t(`${formik.errors.email}`)
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 nopadding">
                    <textarea
                      type="text"
                      placeholder={t("services.leave")}
                      value={formik.values.message}
                      id="message"
                      name="message"
                      required={true}
                      rows={5}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.message && formik.touched.message
                          ? "error"
                          : null
                      }
                    />
                    <p className="errMsg">
                      {formik.errors.message && formik.touched.message
                        ? t(`${formik.errors.message}`)
                        : null}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 nopadding">
                    <div className="input-wrapper">
                      <MdPhoneAndroid className="input-icon" />
                      <input
                        type="text"
                        placeholder={t("quotation.phone")}
                        value={formik.values.phone}
                        id="email"
                        name="phone"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.phone && formik.touched.phone
                            ? "error"
                            : null
                        }
                      />
                      <p className="errMsg">
                        {formik.errors.phone && formik.touched.phone
                          ? t(`${formik.errors.phone}`)
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 nopadding">
                    {err ? <p className="errMsg">{errMsg}</p> : null}
                    {loading ? (
                      <div
                        className="loaderDiv"
                        style={{ height: "60px", marginTop: "1em" }}
                      >
                        <PuffLoader size={40} color="#3B6DEA" />
                      </div>
                    ) : (
                      <button id="submit">{t("quotation.submit")}</button>
                    )}
                  </div>
                </div>
              </div> */}
              <div className="container-fluid">
                <div className="row">
                  {/* Left Side - Inputs */}
                  <div className="col-12 col-md-6 nopadding">
                    <div className="input-wrapper">
                      <MdAccountBox className="input-icon" />
                      <input
                        type="text"
                        placeholder={t("services.name")}
                        value={formik.values.name}
                        id="name"
                        name="name"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.name && formik.touched.name
                            ? "error"
                            : null
                        }
                      />
                      <p className="errMsg">
                        {formik.errors.name && formik.touched.name
                          ? t(`${formik.errors.name}`)
                          : null}
                      </p>
                    </div>
                    <div className="input-wrapper">
                      <MdMail className="input-icon" />
                      <input
                        type="text"
                        placeholder={t("services.email")}
                        value={formik.values.email}
                        id="email"
                        name="email"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.email && formik.touched.email
                            ? "error"
                            : null
                        }
                      />
                      <p className="errMsg">
                        {formik.errors.email && formik.touched.email
                          ? t(`${formik.errors.email}`)
                          : null}
                      </p>
                    </div>
                    <div className="input-wrapper">
                      <MdPhoneAndroid className="input-icon" />
                      <input
                        type="text"
                        placeholder={t("quotation.phone")}
                        value={formik.values.phone}
                        id="phone"
                        name="phone"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.phone && formik.touched.phone
                            ? "error"
                            : null
                        }
                      />
                      <p className="errMsg">
                        {formik.errors.phone && formik.touched.phone
                          ? t(`${formik.errors.phone}`)
                          : null}
                      </p>
                    </div>
                  </div>

                  {/* Right Side - Textarea */}
                  <div className="col-12 col-md-6 nopadding">
                    <textarea
                      type="text"
                      placeholder={t("services.leave")}
                      value={formik.values.message}
                      id="message"
                      name="message"
                      required={true}
                      rows={5}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.message && formik.touched.message
                          ? "error"
                          : null
                      }
                    />
                    <p className="errMsg">
                      {formik.errors.message && formik.touched.message
                        ? t(`${formik.errors.message}`)
                        : null}
                    </p>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="row">
                  <div className="col-12 nopadding">
                    {err ? <p className="errMsg">{errMsg}</p> : null}
                    {loading ? (
                      <div
                        className="loaderDiv"
                        style={{ height: "60px", marginTop: "1em" }}
                      >
                        <PuffLoader size={40} color="#3B6DEA" />
                      </div>
                    ) : (
                      <button id="submit" type="submit">
                        {t("quotation.submit")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Smouha;
