import about1 from "../images/about1.png";
import about2 from "../images/about2.svg";
import { MdAccountBox } from "react-icons/md";
import { MdBusinessCenter } from "react-icons/md";
import { MdMail } from "react-icons/md";
import { MdPhoneAndroid } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import Footer from "../components/Footer";
import "../style/quotation.css";
import Navbar from "../components/Navbar";
import { BsCheck } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { requestQuotation } from "../api";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import Success from "../components/Success";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

function Register() {
  const { t, i18n } = useTranslation();
  const [checkList, setCheckList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);

  const currentLanguage = i18n.language;

  const { lang } = useParams(); // Get the language parameter from the URL

  useEffect(() => {
    if (lang && (lang === "en" || lang === "ar")) {
      i18n.changeLanguage(lang);
      localStorage.setItem("language", JSON.stringify(lang));

      if (lang === "en") {
        document.getElementById("root").style.direction = "ltr";
      } else {
        document.getElementById("root").style.direction = "rtl";
      }

      if (JSON.parse(localStorage.getItem("language")) === "ar") {
        document.title = " بدايات للتكنولوجيا";
      } else {
        document.title = "BDAIAT TECHNOLOGIES";
      }
    }
  }, [lang, i18n]);

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const source = queryParams.get('source');

  // // Now 'source' contains the value indicating the button clicked
  // console.log('Source:', source);

  let emailRegx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("language")) === "ar") {
      document.title = "عرض سعر";
    } else {
      document.title = "QUOTATIONS";
    }
  }, []);

  const Schema = yup.object().shape({
    name: yup.string().required("validation.name"),
    company_name: yup.string().required("validation.company"),
    phone: yup
      .string()
      .required("validation.phone")
      .min(10, "validation.phoneInvalid"),
    email: yup
      .string()
      .required("validation.email")
      .matches(emailRegx, "validation.emailInvalid"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      company_name: "",
      phone: "",
      email: "",
      message: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  const source = localStorage.getItem("source");

  function onSubmit() {
    if (checkList.length == 0) {
      setErr(true);
      setErrMsg(
        i18n.language == "ar"
          ? "تحتاج إلى تحديد الخدمة"
          : "You need to select a service"
      );
    } else {
      setLoading(true);
      const data = {
        name: formik.values.name,
        company_name: formik.values.company_name,
        phone: formik.values.phone,
        email: formik.values.email,
        message: formik.values.message,
        // services: checkList.toString(),
        services: checkList,
        type: "service",
        source: source,
      };

      requestQuotation(data, i18n.language)
        .then((response) => {
          setLoading(false);
          setErr(false);
          setSuccess(true);
          const checkboxes = document.getElementsByClassName("chooseService");

          for (let i = 0; i < checkboxes.length; i++) {
            const checkbox = checkboxes[i];

            if (checkbox.checked) {
              checkbox.checked = false;
            }
          }
          setCheckList([]);
          /*var frm = document.getElementsByName('quotationFrm')[0];
frm.reset(); */
          formik.handleReset();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }

  const [isOtpSent, setIsOtpSent] = useState(false); // Track OTP screen

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simulate form submission logic
    if (formik.isValid) {
      setIsOtpSent(true); // Show OTP field after submission
    }
  };

  function handleCheck(value) {
    const newArr = checkList;
    if (newArr.includes(value)) {
      newArr.splice(newArr.indexOf(value), 1); // Remove value
    } else {
      newArr.push(value); // Add value
    }
    setCheckList(newArr);
  }
  return (
    <div className="aboutUs-container">
      <Helmet>
        <title>Bdaiat Technologies - Quotation</title>
        <link rel="canonical" href="https://www.bdaiat.com/quotation" />

        <script type="application/ld+json">{`
          {"@context":"http://schema.org","@type":"Organization","name":"Bdaiat Technolgoies","@id":"BdaiatTechnologies","url":"https://www.bdaiat.com/","logo":"https://www.bdaiat.com/bdaiat-logo.png","address":{"@type":"PostalAddress","addressLocality":"Sidi Gaber","addressRegion":"Alexandria","postalCode":"21523","streetAddress":"Building #378 Horya Road, Sidi Gaber, Alexandria, Egypt","addressCountry":"Egypt"},"email":"mailto:info@bdaiat.com","contactPoint":[{"@type":"ContactPoint","telephone":"+201097101006","contactType":"Technical Support"}],"sameAs":["https://www.facebook.com/bdaiat","https://www.instagram.com/bdaiat/","https://twitter.com/BdaiatTech","https://www.linkedin.com/company/bdaiat"]}
      `}</script>
      </Helmet>
      {success ? <Success type="success" setModal={setSuccess} /> : null}

      <div
        className={`container-fluid quotationContainer ${
          currentLanguage === "ar" ? "quotationContainerAr" : ""
        }`}
      >
        <div className="row">
          <div
            className={`col-lg-8 col-md-12 quotationForm ${
              currentLanguage === "ar" ? "quotationFormAr" : ""
            }`}
          >
            {!isOtpSent ? (
              // **Quotation Form**
              <form onSubmit={handleSubmit} name="quotationFrm">
                <p id="quotRequest">{t("navbar.quotation")}</p>
                <p id="quotReach">
                  {t("services.reach")}{" "}
                  <a href="mailto:sales@bdaiat.com">sales@bdaiat.com</a>
                </p>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-md-6 nopadding">
                      <div className="input-wrapper">
                        <MdAccountBox className="input-icon" />
                        <input
                          type="text"
                          placeholder={t("services.name")}
                          value={formik.values.name}
                          name="name"
                          required
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.name && formik.touched.name
                              ? "error"
                              : ""
                          }
                        />
                        <p className="errMsg">
                          {formik.errors.name && formik.touched.name
                            ? t(formik.errors.name)
                            : null}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 nopadding">
                      <div className="input-wrapper">
                        <MdBusinessCenter className="input-icon" />
                        <input
                          type="text"
                          placeholder={t("services.compName")}
                          value={formik.values.company_name}
                          name="company_name"
                          required
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.company_name &&
                            formik.touched.company_name
                              ? "error"
                              : ""
                          }
                        />
                        <p className="errMsg">
                          {formik.errors.company_name &&
                          formik.touched.company_name
                            ? t(formik.errors.company_name)
                            : null}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 nopadding">
                      <div className="input-wrapper">
                        <MdPhoneAndroid className="input-icon" />
                        <input
                          type="text"
                          placeholder={t("quotation.phone")}
                          value={formik.values.phone}
                          name="phone"
                          required
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.phone && formik.touched.phone
                              ? "error"
                              : ""
                          }
                        />
                        <p className="errMsg">
                          {formik.errors.phone && formik.touched.phone
                            ? t(formik.errors.phone)
                            : null}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 nopadding">
                      <div className="input-wrapper">
                        <MdMail className="input-icon" />
                        <input
                          type="text"
                          placeholder={t("services.email")}
                          value={formik.values.email}
                          name="email"
                          required
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.email && formik.touched.email
                              ? "error"
                              : ""
                          }
                        />
                        <p className="errMsg">
                          {formik.errors.email && formik.touched.email
                            ? t(formik.errors.email)
                            : null}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 nopadding">
                      {err ? <p className="errMsg">{errMsg}</p> : null}
                      {loading ? (
                        <div
                          className="loaderDiv"
                          style={{ height: "60px", marginTop: "1em" }}
                        >
                          <PuffLoader size={40} color="#3B6DEA" />
                        </div>
                      ) : (
                        <button id="submit">{t("quotation.submit")}</button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              // **OTP Verification Screen**
              <div className="col-12 col-md-6 nopadding">
                <div className="otp-container">
                  <p id="quotReach">{t("quotation.otp")}</p>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder={t("quotation.enterOtp")}
                      name="otp"
                      required
                    />
                  </div>
                    <button className="mt-3" id="submit" onClick={() => alert("Invalid OTP!")}>
                      {t("quotation.verifyOtp")}
                    </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div id="quotDiv">
        <p id="contact">{t("quotation.contact")}</p>
        <p id="hear">{t("quotation.hear")}</p>
        <p id="chat">{t("quotation.chat")}</p>
        <div
          className="compImg"
          style={{ backgroundImage: `url(${about1})`, margin: "6vw 0" }}
        ></div>

        <div className=" strech">
          <div className="row  gy-4 contDiv-flex">
            <div className="col-lg-4 col-sm-6 noPadding">
              <div
                className="our contDiv"
                style={
                  i18n.language == "ar"
                    ? { textAlign: "right" }
                    : { textAlign: "left" }
                }
              >
                <div className="contactIcon">
                  <HiOutlineChatBubbleLeftRight size={24} />
                </div>
                <div>
                  <p id="contType">{t("quotation.sales")}</p>
                  <p id="contTxt">{t("quotation.speak")}</p>
                  <p id="cont" className="underline">
                    {/* ab.yakout@bdaiat.com */}
                    sales@bdaiat.com
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 noPadding ">
              <div
                className="our contDiv"
                style={
                  i18n.language == "ar"
                    ? { textAlign: "right" }
                    : { textAlign: "left" }
                }
              >
                <div className="contactIcon">
                  <HiOutlineLocationMarker size={24} />
                </div>
                <div>
                  <p id="contType">{t("quotation.visit")}</p>
                  <p id="contTxt">{t("quotation.speak")}</p>
                  <p id="cont">{t("footer.comp2")}</p>
                </div>{" "}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 noPadding">
              <div
                className="our contDiv"
                style={
                  i18n.language == "ar"
                    ? { textAlign: "right" }
                    : { textAlign: "left" }
                }
              >
                <div className="contactIcon">
                  <FiPhoneCall size={24} />
                </div>
                <div>
                  <p id="contType">{t("quotation.call")}</p>
                  <p id="contTxt">{t("quotation.speak")}</p>
                  <p id="cont" className="underline">
                    (+2) 0109 710 1006
                  </p>
                  <p id="cont" className="underline">
                    (+2) 0100 075 9105
                  </p>
                  <p id="cont" className="underline">
                    (+966) 53 463 8774
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
